<template>
  <div id="common">
    <div class="page" size="A4" v-for="page in pages" :key="'page' + page">
      <v-row class="pt-2">
        <v-col class="d-flex align-center">
          <v-avatar size="50">
            <img
              src="https://links.casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
            />
          </v-avatar>
          <b style="font-size: 20px">Rede Casas das Tintas</b>
        </v-col>

        <v-col cols="3" style="font-size: 10px; line-height: 1pt">
          <div class="text-center">
            <VueBarcode
              height="30"
              :fontSize="10"
              :value="delivery.number"
              :text="`Entrega N° ${delivery.number}`"
            />
          </div>
        </v-col>
      </v-row>
      <hr class="ma-3" />

      <v-row class="text-center">
        <v-col><h2>Romaneio de Entrega</h2> </v-col>
      </v-row>
      <v-row
        v-if="isFirstPage(page)"
        class="d-flex justify-space-between align-center"
      >
        <v-col cols="7">
          <b>Dados Do Cliente</b>
          <div>
            <template v-if="hasCustomer">
              <span>
                <b>Nome:</b>
                {{ delivery.sale.customer.code }} -
                {{ delivery.sale.customer.name }}
              </span>
              <br />

              <app-show-phones
                class="d-flex"
                style="justify-content: space-between"
                :phones="delivery.sale.customer.phones"
              />
            </template>
            <div v-else>
              <span> <b>Nome: </b> Consumidor Final </span>
            </div>
            <span v-if="delivery.main_address">
              <b>Endereço: </b>
              {{ delivery.main_address.address }},
              {{ delivery.main_address.number }},
              {{ delivery.main_address.district }},
              {{ delivery.main_address.city }}-
              {{ delivery.main_address.state }},
              {{ $format.cep(delivery.main_address.postal_code) }}
              <span v-if="delivery.main_address.complement">
                <br />
                {{ delivery.main_address.complement }}
              </span>
            </span>
          </div>
        </v-col>
        <v-col cols="4">
          <div><b>Venda :</b> N º{{ delivery.sale.number }}</div>
          <div>
            <b>Data Compra :</b>{{ $format.dateBr(delivery.sale.created_at) }}
          </div>
          <div>
            <b>Data Entrega :</b>{{ $format.dateBr(delivery.scheduled_to) }}
          </div>
          <div><b>Peso: </b>{{ delivery.sale.weight_gross }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :page="page"
            :headers="headers"
            :items="getItems(page)"
            :items-per-page="itensPerPage"
            hide-default-footer
            disable-sort
            dense
          >
            <template v-slot:[`item.product`]="{ item }">
              <span v-if="item.product">
                <b> {{ item.product_company.company.short_name }}</b> -
                {{ item.product.code }} - {{ item.product.name }}</span
              >
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <span v-if="item.product">
                {{ $format.decimal(item.quantity) }}
                {{ item.product_variant.unity.symbol }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <template v-if="page == pages">
        <v-row class="text-center mt-10">
          <v-col>
            <v-divider />
            Estoquista Responsável
          </v-col>
          <v-col>
            <v-divider />
            Assinatura
          </v-col>
        </v-row>
        <v-row style="word-break: break-word">
          <v-col> Observação: {{ delivery.comment }} </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import AppShowPhones from "@/components/app/sections/AppShowPhones.vue";
import VueBarcode from "vue-barcode";

export default {
  components: { AppShowPhones, VueBarcode },
  props: {
    delivery: {},
  },
  data() {
    return {
      itensPerPage: 17,

      headers: [
        { text: "Produto", value: "product" },

        { text: "Entrega	", value: "quantity", align: "center", width: "10%" },
      ],
    };
  },

  computed: {
    hasCustomer() {
      return this.delivery.sale.customer_id;
    },

    personType() {
      return this.delivery.customer.person_type;
    },
    docLabel() {
      return this.personType == "juridica" ? "CNPJ:" : "CPF:";
    },
    docNumber() {
      return this.personType == "juridica"
        ? this.$format.cnpj(this.delivery.customer.cnpj)
        : this.$format.cpf(this.delivery.customer.cpf);
    },

    pages() {
      return Math.ceil(this.itensCount / this.itensPerPage);
    },

    delivery_products() {
      return this.delivery.delivery_products;
    },
    itensCount() {
      if (this.delivery_products) {
        return this.delivery_products.length;
      }
      return 1;
    },
  },
  methods: {
    hasItemOnIndex(page, item) {
      return this.getIndex(page, item) < this.itensCount;
    },
    isFirstPage(page) {
      return page == 1;
    },

    getItems(page) {
      let items = this.delivery_products.slice(
        (page - 1) * this.itensPerPage,
        page * this.itensPerPage
      );
      if (items.length < this.itensPerPage) {
        for (let i = items.length; i < this.itensPerPage; i++) {
          items.push({});
        }
      }
      return items;
    },
  },
};
</script>

<style  lang="scss">
@import "@/assets/style/prints/CommonPrinting.scss";
</style>
